import React from "react";
import styled from "styled-components";
import {Box, Grid, Typography} from "@material-ui/core";
import Background from "./ui/Background";
import {Parallax} from "react-scroll-parallax";
import {isMobile} from "react-device-detect"
import {Image} from "semantic-ui-react";

const AboutWrapper = styled.div<{ time: number }>`
  padding-top: 50px;
  color: ${(props) => props.time >= 18 || props.time <= 6 ? '#fff' : "black"};
`
const AboutHeader = styled(Typography)`  
  font-family: 'roboto';
  text-transform: uppercase;
  font-weight: 100;
`

const AboutText = styled(Typography)`
  font-family: 'roboto';
  font-weight: 100;
  text-transform: uppercase;
  padding-top: 20px;
  line-height: 2em;
  font-weight: 100 !important;
  letter-spacing: !important 3px;

  padding-bottom: 50px;
`;

const StyledImage = styled(Image)`
	height: 100%;
	width: 100%;
`

const About: React.FC = () => {
  const hours = new Date().getHours();
  
  return (
    <>
    <Background>
      <AboutWrapper time={hours}>
    <Grid
      container
      spacing={0}
      direction={"column"}
      alignItems={'center'}
      justify={'center'}
      alignContent={'center'}
      >
          <Grid item xs={12}>
      <Parallax className="custom-class" x={[-20, 0]} tagOuter="figure">
            <AboutHeader variant={'h1'} align={'center'}>
              <Box letterSpacing={12}>
                About us
              </Box>
            </AboutHeader>
      </Parallax>
          </Grid>
    </Grid>
          <Grid container item justify='center'>
            <Grid item xs={isMobile ? 12 : 6}>
              <Box mx={!isMobile ? 10 : 0}>
                <Parallax className="custom-class" x={[-30, 10]} tagOuter="figure">
  
          <Box mt={!isMobile ? 10 : 0} letterSpacing={6}>
                <AboutText variant={'h5'} align={'left'}>
              Located in downtown Calgary, UndrStateMnt is a premier barbershop that provides a high level of care and experience to our clientele.
              <br/>
              Driven by our passion and expertise, our clients are confident that they can count on our professional service. They know that they will leave our salon feeling and looking their best.
                  <br/>
                  <br/>
              Undrstatemnt's goal is to have a shop deeply rooted in the foundation of the community, somewhere their clientele can surround themselves with amazing people through the years.
                </AboutText>
          </Box>
              </Parallax>
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 2 : 6}>
            <Parallax className="custom-class" x={[0, -15]} tagOuter="figure">
              { !isMobile &&
                <StyledImage
                src={"https://res.cloudinary.com/shino/image/upload/v1596760923/undrstatemnt/JEB09896.ARW.jpg"} />
              }
              </Parallax>
          </Grid>
          </Grid>
            {/* <PictureCarousel/> */}
    </AboutWrapper>
    </Background>
  </>
  )
}

export default About;
