import React from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import styled from "styled-components";
import {isMobile} from "react-device-detect"


const Header = styled.p`
  font-family: 'roboto';
  letter-spacing: 4px;
  font-size: 20px;
  padding-top: 30px;
  text-transform: uppercase;
  font-weight: bold;
`;

const ContactHeader = styled(Typography)`
  font-family: 'roboto';
  text-transform: uppercase;
  font-weight: 100;
`

const Text = styled(Typography)`
  font-family: 'roboto';
  letter-spacing: 4px;
  font-size: 18px;
  font-weight: 600;
`
const Contact: React.FC = () => {
	return(
		<Box mt={10}>
			<Grid container item justify={'center'}>
				<Grid item xs={12}>
					<ContactHeader  variant={'h1'} align={'center'}>
						Contact Us
					</ContactHeader>
				</Grid>
			</Grid>
				<Grid container item justify={'center'}>
					<Box mx={2}>
				<Grid item xs={12}>
					<Header>
						Address
					</Header>
					<Text variant={'h5'} align={'left'}>
						UndrStatemnt Parlour
						825 12 Ave SW
						Suite B100 Buzz # 105
						Calgary, AB
						T2R 0J2
					</Text>
					<Header>
						Email
					</Header>
					<Text variant={'h5'} align={'left'}>						undrstatemnt@gmail.com
					</Text>
					<Header>
						Social Media
					</Header>
					<Text variant={'h5'} align={'left'}>						instagram: @undrstatemnt
						fb: www.facebook.com/undrstatemnt/
					</Text>
				</Grid>
					</Box>
				</Grid>
					<Grid container item justify={'center'}>
					<Grid item xs={isMobile ? 12 : 4}>
						<Box my={10}>
						<iframe
							title={"undrstatemnt"}
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2508.6142185421318!2d-114.08270168388893!3d51.041745552462736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53716f60e600a8db%3A0x6b0573dbce9f26c9!2sUndrStatemnt%20Parlor!5e0!3m2!1sen!2sca!4v1598408773810!5m2!1sen!2sca"
							width={isMobile ? "400" : "600"} height="450" frameBorder="0"/>
						</Box>
				</Grid>
					</Grid>
		</Box>
	)
}

export default Contact;