import React from "react";
import styled from "styled-components";
import {isMobile} from "react-device-detect"


const Overlay = styled.div<{isMobile: boolean}>`
  position: absolute;
  backdrop-filter: blur(20px);
  background-color: rgba(0,0,0,0.1);
  height: ${(props) => props.isMobile ? "25%" : "100%"};
  width: ${(props) => props.isMobile ? "100%" : "33%"};
  top: 0;
  padding: 130px 0 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  z-index: 2;
`
const Video = styled.video`
	object-fit: cover;
`
const VideoDiv = styled.div`
	height: 100vh;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  overflow: hidden;
  -webkit-animation: heroFadeIn 1.4s cubic-bezier(0.23,1,0.32,1) backwards;
  animation: heroFadeIn 1.4s cubic-bezier(0.23,1,0.32,1) backwards;
`

const LandingPage: React.FC = () => {
  return (
    <div>
			<VideoDiv>
			<Overlay isMobile={isMobile}/>
				<Video autoPlay playsInline muted loop height={"100%"} width={"100%"}>
					<source src={"https://res.cloudinary.com/shino/video/upload/v1597807035/undrstatemnt/Copy_of_Undrstatemnt_Promo_MP4.mov"}/>
				</Video>
			</VideoDiv>
		</div>
		
	)
}

export default LandingPage;
