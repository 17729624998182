import React from "react";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import Background from "./ui/Background";
import styled from "styled-components";
import Cards from "./ui/Cards";

const Wrapper = styled.div<{time: number}>`
  padding-top: 200px;
  color: ${(props) => props.time >= 18 || props.time <= 6 ? '#fff' : "black"};
`
const Header = styled(Typography)`
  font-family: 'roboto';
  text-transform: uppercase;
  font-weight: 100;
  font-size: 100px;
  line-height: 1.1em;
  margin-top: 100px;
`
const Book: React.FC = () =>{
	const hours = new Date().getHours();
	return(
		<Background>
			<Grid
				container
				spacing={0}
				direction={"column"}
				alignItems={'center'}
				justify={'center'}
				alignContent={'center'}
			>
				<Wrapper time={hours}>
					<Background>
						<Box pb={8}>
							<Grid container item xs >
								<Grid item xs={12}>
									<Header variant={'h1'} align={'center'}>
										BOOK NOW
									</Header>
								</Grid>
							</Grid>
						</Box>
						<Grid container item justify='center'>
							<Grid item xs={12}>
								<Cards/>
								<Button />
							</Grid>
						</Grid>
					</Background>
				</Wrapper>
			</Grid>
		</Background>
		
	)
}
export default Book;
