import React from 'react';
import About from './About';
import Service from "./Service";
import Contact from "./ui/Contact";
import LandingPage from "./ui/LandingPage";
import Nav from "./ui/Nav";
import Book from "./Book";
import {createMuiTheme, responsiveFontSizes, ThemeProvider} from '@material-ui/core/styles';
import styled from "styled-components";
import {ParallaxProvider} from "react-scroll-parallax/cjs";
import {isMobile} from "react-device-detect"
import {Element} from 'react-scroll'

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);
const Div = styled.div`
  overflow-x: hidden;
`
const Navbar = styled.header<{isMobile: boolean}>`
  position: absolute;
  left: ${(props) => props.isMobile ? "-50px" : "50px"};
`

const App: React.FC = () => {
  
  return (
    <ParallaxProvider>
  
 <Div className="App">
      <ThemeProvider theme={theme}>
      <Navbar isMobile={isMobile}>
        <Nav/>
      </Navbar>
        <Element name={"home"}>
        <LandingPage/>
        </Element>
        <Element name={"about"}>
        <About/>
        </Element>
        <Element name={"services"}>
      <Service/>
        </Element>
        <Element name={"book"}>
      <Book/>
        </Element>
        <Element name={"contact"}>
      <Contact/>
        </Element>
      </ThemeProvider>
    </Div>
    </ParallaxProvider>
  );
}

export default App;
