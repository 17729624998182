import React from "react";
import styled from "styled-components";
import DarkImgSrc from "../../src/static/img-noise-1000x1000.png"
import LightImgSrc from "../../src/static/img-noise-light.png"

const hours = new Date().getHours();

const BackgroundImage = hours >= 18 || hours <= 6 ? DarkImgSrc : LightImgSrc;

const BackgroundWrapper = styled.div<{paddingTop?: string}>`
  background-image: url(${BackgroundImage});
  padding-top: ${props => props.paddingTop};
`

interface Props {
  paddingTop?: string;
}

const Background: React.FC<Props> = ({ paddingTop , children: content})  => {
  return (
    <>
      <BackgroundWrapper paddingTop={paddingTop}>
        {content}
      </BackgroundWrapper>
    </>
  );
}

export default Background;