import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import {Box, Button, Grid} from "@material-ui/core";

const StyledCard = styled(Card)`
  margin-top: 50px;
  font-family: 'roboto';
  height: 1000px;
  width: 400px;
`

const StyledButton = styled(Button)`
  && {
    background-color: #7ADFBB;
    width: 30%;
  }
`
const StyledImage = styled.img`
  background-color: rgb(0,0,0);
  width: 100%;
  height: 500px;
`

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 100%;
`
const StyledCardActions = styled(CardActions)`
  justify-content: center;
`

const InstagramLink = styled.a`
   &:hover {
    text-decoration: underline;
    color: #7ADFBB;
  }
  text-decoration: none;
  color: black;
`

const Avery = () => {
  return (
  <StyledCard>
    <CardActionArea>
      <Overlay>
        <CardMedia>
          <StyledImage src={"https://res.cloudinary.com/shino/image/upload/v1597374350/undrstatemnt/JEB09658.ARW.jpg"}
                       alt={"Avery Casem"}/>
        </CardMedia>
      </Overlay>
      <CardContent>
        <Box mt={4}>
          <Typography align='center' gutterBottom variant="h5" component="h2">
            Avery Casem
            <br/>
            <InstagramLink href={"https://www.instagram.com/avery.casem/"}>
            @avery.casem
            </InstagramLink>
          </Typography>
          <Typography align='center' variant="body2" color="textSecondary" component="p">
            Avery discovered his passion for barbering, at the ripe age of 15, when a good friend of his received a bad haircut from a mall salon.
            He had no idea what he was doing, all that mattered to him was to fix his friend's hair.
            After moving to Calgary, he completed education at Delmar Hair college and became an apprentice at Lokal Fokas Headshop, under the tutelage of master hairstylist, Bless aka Jordan Capulong.
            Bless provided a place to hone his skills and learn the ins and outs of the industry, and inspired Avery to forge his own path in Undrstatemnt.
            Being able to combine art, creativity, and making people feel more confident as they leave his chair is what drives Avery's passion.
            Not a man of many words, Avery's cuts can do the talking for him.
          </Typography>
        </Box>
      </CardContent>
    </CardActionArea>
    <StyledCardActions>
      <StyledButton size="small">
        <a href={"https://booksy.com/en-ca/7556_averycasem_hair-salons_380718_calgary"}>
        Book
        </a>
      </StyledButton>
    </StyledCardActions>
  </StyledCard>
  )
}
const Justav = () => {
  return (
    <StyledCard>
      <CardActionArea>
        <Overlay>
          <CardMedia>
            <StyledImage src={"https://res.cloudinary.com/shino/image/upload/v1597374350/undrstatemnt/JEB09666.ARW.jpg"}
                         alt={"Justav Flores"}/>
          </CardMedia>
        </Overlay>
        <CardContent>
          <Box mt={4}>
            <Typography align='center' gutterBottom variant="h5" component="h2">
              Justav Flores
              <br/>
              <InstagramLink href={"https://www.instagram.com/bankrolljeezy/"}>
                @bankrolljeezy
              </InstagramLink>
            </Typography>
            <Typography align='center' variant="body2" color="textSecondary" component="p">
              Justav has been cutting hair since his high school days, practicing on close homies and the basketball team.
              In 2016 he started under the skilled Jordan Bless Capulong at Second Mile Barbershop. Having 1 year from a previous shop, and another 2 at Second Mile,
              he then decided to further his knowledge and education by going to TOC akademy. After graduating from hair school and adding a few more years of experience
              he and Avery decided to venture off and start "Undrstatemnt".
              The main goal Justav has for the shop is to create a great and safe atmosphere for his clients. Also to provide them with a solid haircut, and confidence boost.
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      <StyledCardActions>
        <StyledButton size="small">
          <a href={"https://booksy.com/en-ca/7591_justav-flores_hair-salons_380718_calgary"}>
          Book
          </a>
        </StyledButton>
      </StyledCardActions>
    </StyledCard>
  )
}

const Jheremy = () => {
  return (
    <StyledCard>
      <CardActionArea>
        <Overlay>
          <CardMedia>
            <StyledImage src={"https://res.cloudinary.com/shino/image/upload/v1597374353/undrstatemnt/JEB09692.ARW.jpg"}
                         alt={"Jheremy Nicdao"}/>
          </CardMedia>
        </Overlay>
        <CardContent>
          <Box mt={4}>
            <Typography align='center' gutterBottom variant="h5" component="h2">
              Jheremy Nicdao
              <br/>
              <InstagramLink href={"https://www.instagram.com/bootlegbishop"}>
              @bootlegbishop
              </InstagramLink>
            </Typography>
            <Typography align='center' variant="body2" color="textSecondary" component="p">
              Calgary born and raised.
              Started barbering at 19, he wanted get away from dead end jobs with no fulfillment. He was inspired by a close friend who was in the industry.
  
              Jheremy started cutting out of Olympus Boxing Club/iBalance and made his way to Second Mile Barbershop, then eventually to UndrStatemnt.
  
              His goal is to continue to grow with the UndrStatemnt team, get his Red seal license, and eventually come out with a product line.
              
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      <StyledCardActions>
        <StyledButton size="small">
          <a href={'https://booksy.com/en-ca/7629_jheremy-nicdao_barbers_380718_calgary'}>
          Book
          </a>
        </StyledButton>
      </StyledCardActions>
    </StyledCard>
  )
}


const Cards: React.FC = () => {
  return (
    <Box pb={20}>
    <Grid
      container
      spacing={10}
      alignItems={'center'}
      justify={'center'}
      alignContent={'center'}
    >
      <Box mx={2}>
        <Grid item md={4}>
          <Avery/>
        </Grid>
      </Box>
      <Box mx={22}>
      <Grid item md={4}>
        <Justav/>
      </Grid>
      </Box>
      <Box mx={2}>
      <Grid item md={4}>
        <Jheremy/>
      </Grid>
      </Box>
    </Grid>
    </Box>
  );
}

export default Cards;
