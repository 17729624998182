import React from "react";
import styled from "styled-components";
import {Link} from 'react-scroll'


const TopBar = styled.div`
	padding-bottom: 20px;
	position: absolute;
	width: 100%;
	top: 0;
	z-index: 9;
	opacity: 1;
	-webkit-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
`
const Content = styled.div`
	position: absolute;
	margin: 0 auto;
	padding: 0 7vw;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`
const TopBarInner = styled.div`
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    position: absolute;
    align-items: center;
    grid-template-columns: repeat(12,1fr);
`

const StyledLogo = styled.img`
    height: 150px;
    display: block;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    align-self: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
`
const NavItem = styled.div<{grid?: number}>`
	padding-left: 250px;
  grid-column: ${props => props.grid};
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  display: grid;
  color: #fff;
  align-self: center;
  height: 36px;
  font-size: 24px;
  line-height: 20px;
  font-family: "Helvetica Neue",sans-serif;
  text-transform: uppercase;
  font-weight: 200;
`
const NavLink = styled(Link)`
	cursor: pointer;
	&:hover{
		color: #7ADFBB
	}
`

const Links = styled.a`
	cursor: pointer;
	&:hover{
		color: #7ADFBB
	}
  color: white;
  text-decoration: none;
`

const Nav: React.FC= () => {
	return(
		<TopBar>
			<Content>
			<TopBarInner>
				<StyledLogo src={"https://res.cloudinary.com/shino/image/upload/v1596765580/undrstatemnt/logo.svg"}/>
				<NavItem grid={-1/-2}>
					<NavLink activeClass="active" to="home" spy={true} smooth={true} duration={500} >HOME</NavLink>
					<NavLink activeClass="active" to="about" spy={true} smooth={true} duration={500} >ABOUT</NavLink>
					<NavLink activeClass="active" to="services" spy={true} smooth={true} duration={500} >SERVICES</NavLink>
					<NavLink activeClass="active" to="book" spy={true} smooth={true} duration={500} >BOOK</NavLink>
          <NavLink activeClass="active" to="contact" spy={true} smooth={true} duration={500} >CONTACT</NavLink>
          <Links href="https://undrstatemnt.square.site">SHOP</Links>
				</NavItem>
			</TopBarInner>
			</Content>
		</TopBar>
	)
}

export default Nav;