import {Box, Button, Grid, Typography} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Background from "./ui/Background";
import { Parallax } from "react-scroll-parallax/cjs";
import {Image} from "semantic-ui-react";
import { isMobile } from "react-device-detect";


const ServicesWrapper = styled.div<{time: number}>`
  padding-top: 200px;
  color: ${(props) => props.time >= 18 || props.time <= 6 ? '#fff' : "#000"};
`
const ServicesHeader = styled(Typography)<{time: number}>`
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	text-transform: uppercase;
  font-weight: 100;
  font-size: 100px;
  line-height: 1.1em;
  margin-top: 100px;
  color: ${(props) => props.time >= 18 || props.time <= 6 ? '#fff' : "#000"};

`

const Services = styled.p<{time: number}>`
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  padding-top: 30px;
  text-transform: uppercase;
  font-weight: 400;
  color: ${(props) => props.time >= 18 || props.time <= 6 ? '#fff' : "#000"};

`;

const ServicesText = styled.p<{time: number}>`
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: ${(props) => props.time >= 18 || props.time <= 6 ? '#fff' : "#000"};
`
const StyledImage = styled(Image)`
	height: 90%;
	width: 90%;
`

const Wrapper = styled.div<{ isMobile: boolean }>`
 text-align: ${(props) => props.isMobile ? "center" : "right"};
`

const Service: React.FC = () => {
const hours = new Date().getHours();

return(
		<>
			<Background>
				<Grid
					container
					spacing={0}
					direction={"column"}
					alignItems={'center'}
					justify={'center'}
					alignContent={'center'}
				>
				<Parallax className="custom-class" x={[20, -10]} tagOuter="figure">
					<ServicesWrapper time={hours}>
						<Background>
							<Box pb={8}>
								<Grid container item xs >
									<Grid item xs={12}>
										<ServicesHeader variant={'h1'} align={'center'} time={hours}>
											OUR Services
										</ServicesHeader>
									</Grid>
									</Grid>
							</Box>
							<Grid container item justify='center'>
								<Grid item xs={isMobile ? 12 : 6}>
									{ !isMobile &&
										<StyledImage
										alt={""}
										src={"https://res.cloudinary.com/shino/image/upload/v1596760923/undrstatemnt/JEB09731.ARW.jpg"} />
									}
									</Grid>
								<Grid item xs={isMobile ? 12 : 6}>
									<Wrapper isMobile={isMobile}>
									<Services time={hours}>
										Men's Haircut  -  $35
									</Services>
									<ServicesText time={hours}>
										Full haircut includes line-up with straight razor finish.
									</ServicesText>
									<Services time={hours}>
										Haircut & Beard Shape-Up - $45
									</Services>
									<ServicesText time={hours}>
										Includes haircut of your choice, line-up with straight razor finish and a beard shape-up.
									</ServicesText>
									<Services time={hours}>
										Beard Trim & Shape-Up - $20
									</Services>
									<ServicesText time={hours}>
										Includes a Beard Trim and Shape Up to help maintain that fresh look.
									</ServicesText>
									<Services time={hours}>
										Sides Only + Lineup - $30
									</Services>
									<ServicesText time={hours}>
										Includes your choice of fade, and a lineup for that extra clean and crisp look.
									</ServicesText>
									<Services time={hours}>
										Kids Haircut - $30
									</Services>
									<ServicesText time={hours}>
										Your choice of kid's haircut.
									</ServicesText>
									<Button />
								</Wrapper>
							</Grid>
						</Grid>
						</Background>
					</ServicesWrapper>
				</Parallax>
			</Grid>
			</Background>
		</>
	)
}

export default Service;